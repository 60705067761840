$top-bar-height-mobile: rem-calc(60);
$top-bar-small-offset: 80px;

.top-bar {
  height: $top-bar-height-mobile;
  z-index: 10;
  backface-visibility: hidden;
  transition: background 0.1s ease;
  transform: height 0.2s ease;
  background: transparent;
  margin: 0 auto;
  padding: 0 rem-calc(15);
  max-width: rem-calc($global-width);
  position: relative;

  @include breakpoint(large) {
    height: rem-calc(90);
    border: 0;
  }

  @include breakpoint(medium down) {
    padding-right: 0;
    padding-left: rem-calc(25);
  }

  ul {
    background: transparent;
  }

  a {
    color: $white;
    font-size: rem-calc(16);
  }

  .menu {
    a:not(.button) {
      color: $black;
      margin: rem-calc(0 18);
      padding: rem-calc(16 0);
      vertical-align: middle;
      transition: color 0.15s ease;
      background: transparent;

      &:hover {
        color: $primary-color;
      }
    }

    .button {
      font-size: rem-calc(15);
      margin-left: rem-calc(10);
      min-width: auto;
      margin-bottom: 0;
      padding: rem-calc(15);
    }

    li.active {
      a:not(.button) {
        color: $secondary-color;
        background: transparent;
      }

      .button {
        color: $black;
      }
    }
  }

  .top-bar-right {
    display: flex;
    justify-content: flex-end;
    flex: 1;

    .hide-for-large {
      @include breakpoint(medium down) {
        display: flex !important;
      }
    }
  }

  .top-bar-left {
    flex: 0 0 auto;
    display: flex;
    align-items: center;

    .top-bar__contact {
      margin-left: rem-calc(40);
      margin-top: 5px;
      display: flex;
      align-items: center;

      span {
        margin-right: 5px;
        margin-top: 3px;
      }
    }
  }
}

.top-bar-title {
  margin: 0;

  @include breakpoint(large down) {
    width: 100%;
  }
}

.top-bar-logo {
  display: block;
  text-align: center;
  background-image: url("../img/logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  width: 125px;
  height: 40px;

  @include breakpoint(small only) {
    width: 120px;
    height: 50px;
    background-size: auto;
    background-position: left center;
  }
}


.top-bar-container {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  background: transparent;

  .sticky {
    transition: background $global-transition, box-shadow $global-transition;
  }

  .is-stuck {
    background: #FCF6F8;
    position: fixed;
    box-shadow: 0 10px 35px rgba($primary-color, 0.08);

    .menu li .light{
      background-color: $primary-color;
      color: $white;
    }
  }

}

.sticky-topbar {
  background: transparent;
}

.top-bar-toggle {
  height: rem-calc(55);
  width: rem-calc(55);
  font-size: rem-calc(14);
  text-transform: uppercase;
  color: $black;
  cursor: pointer;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem-calc(12);
  font-weight: 500;

  @include breakpoint(large) {
    line-height: rem-calc(40);
  }

  @include breakpoint(small only) {
    width: rem-calc(60);
    //position: absolute;
    //right: 0;
    //top: 0;
  }

  span:last-child {
    padding-left: rem-calc(15);

    @include breakpoint(small only) {
      display: none;
    }
  }
}

/*
|--------------------------------------------------------------------------
| Off canvas
|--------------------------------------------------------------------------
*/
.off-canvas {
  color: $white;
  padding: rem-calc(20 20 30 30);
  font-size: rem-calc(16);
  display: flex;
  flex-flow: column;

  @include breakpoint(medium) {
    padding: rem-calc(40 60 30 60);
  }

  &.is-transition-overlap {
    z-index: 9999;
  }

  &__header {
    margin-bottom: rem-calc(30);
    @include breakpoint(medium) {
      margin-bottom: rem-calc(60);
    }
  }

  &__footer {
    margin-top: auto;
    .muted{
      font-size: rem-calc(13);
      display: block;
      margin-bottom: rem-calc(10);
    }
    
  }

  a:not(.button) {
    color: $white;
    text-align: left;
  }

  .button {
    width: 100%;
    font-size: rem-calc(15);
    
  }

  .menu {
    margin-bottom: rem-calc(20);
    margin-top: rem-calc(20);
    

    .is-active>a {
      background-color: transparent;
      color: $white;
    }

    a {
      font-family: $header-font-family;
      font-weight: bold;
      outline: none;
      padding: rem-calc(18 0);
      font-size: rem-calc(17);
      line-height: rem-calc(20);
      transition: color $global-transition;
      @include breakpoint(small only) {
        padding: rem-calc(18 0);
      }

      &:focus,
      &:hover {
        color: $secondary-color;
        text-decoration: underline;
      }
    }

    li.current_page_item > a {
      color: $secondary-color;
      text-decoration: underline;
    }
    .button{
      margin-top: rem-calc(20);
    }
  }

  .close-button {
    right: 20px;
    top: 10px;
    color: $white;
    font-size: rem-calc(40);
    opacity: 0.8;

    @include breakpoint(medium) {
      right: 35px;
      top: 35px;
    }
  }

  .headline {
    color: $white;
  }
}


/*
|--------------------------------------------------------------------------
| Other
|--------------------------------------------------------------------------
*/
.hamburger {
  @include hamburger($color: $white, $color-hover: $white, $height: 16px);
  position: relative;
  top: -1px;

  &:after {
    height: 2px;
  }
}