.grid-container {
    @include breakpoint(medium down) {
        padding-left: rem-calc(25);
        padding-right: rem-calc(25);
    }
}

.main {
    background-image: url('../img/bg-hero.svg');
    background-position: calc(50% + 380px) -90px;
    background-repeat: no-repeat;
    background-size: auto;
    overflow-x: hidden;
}

.hero {
    padding: rem-calc(100 0 0 0);
    position: relative;

    @include breakpoint(large) {
        padding: rem-calc(200 0 0 0);
    }

    .headline {
        margin-bottom: rem-calc(30);


    }

    .lead {
        margin-bottom: rem-calc(60);
        font-size: rem-calc(22);

        @include breakpoint(small only) {
            margin-bottom: rem-calc(0);
        }
    }

    &__benefits {
        font-size: rem-calc(13);
        line-height: 130.7%;
        margin-top: rem-calc(15);
    }

    &__promo {
        width: rem-calc(140);
        height: rem-calc(140);
        border-radius: 50%;
        background-color: #F9D257;
        display: flex;
        align-items: center;
        margin: 0 auto;
        justify-content: center;
        margin-bottom: rem-calc(-15);



        transform: rotate(-4deg) translateZ(0);
        font-size: rem-calc(14);
        line-height: 120%;

        @include breakpoint(small only) {
            margin-left: 49vw;
        }


        @include breakpoint(medium) {
            position: absolute;
            right: auto;
            left: 67%;
            bottom: 23%;
            font-size: rem-calc(18);
            width: rem-calc(220);
            height: rem-calc(220);
        }
    }

    &__funded {
        margin-top: rem-calc(57);

        p {
            font-size: rem-calc(12);
            line-height: 120%;

            color: rgba(17, 13, 42, 0.4);
            text-align: left;
        }

        img {
            @include breakpoint(small only) {
                margin-bottom: rem-calc(20);
            }
        }

        hr {
            border-color: rgba(0, 0, 0, 0.09);
            margin-top: rem-calc(-10);
            margin-bottom: rem-calc(37);
        }
    }
}

.section {
    padding: rem-calc(50 0 20 0);

    @include breakpoint(medium) {
        padding: rem-calc(50 0 80 0);
    }

    &__title {
        @include breakpoint(medium) {
            margin-bottom: rem-calc(60);
        }
    }

    &--video {

        margin: rem-calc(40 0);
        padding: 0;

        video {
            width: 100%;
        }
    }

    &--team {
        @include breakpoint(medium) {
            padding-top: rem-calc(100);
        }

        .grid-container:first-child {
            margin-bottom: rem-calc(90);

            @include breakpoint(medium) {
                margin-bottom: rem-calc(190);
            }
        }

        .grid-container:last-child {
            margin-bottom: rem-calc(40);


        }
    }

    &--newsletter {
        text-align: center;
        color: $white;
        background-color: $primary-color;
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center 40px;
        text-align: left;
        padding: rem-calc(50 0 20 0);

        @include breakpoint(medium) {
            padding: rem-calc(111 0 150 0);
        }

        .headline {
            margin-bottom: rem-calc(30);

            @include breakpoint(medium) {
                margin-bottom: rem-calc(80);
            }
        }

        label {
            color: $white;

            a {
                color: $white;
                text-decoration: underline;
            }
        }

    }

    &--download {
        background: #F9D155;
        padding: rem-calc(60 0 30 0);
        text-align: center;

        @include breakpoint(medium) {
            padding: rem-calc(250 0 30 0);
        }

        .lead {
            margin-bottom: 0;
            font-size: rem-calc(22);
            line-height: 130%;

            a {
                color: $black;
                text-decoration: underline;
            }
        }

        img {
            @include breakpoint(medium) {
                width: 354px;

            }
        }

        .benefits {
            font-size: rem-calc(16);
            line-height: 130.7%;
            margin: rem-calc(55 0);
        }
    }
}

.box {
    box-shadow: 0px 14px 24px rgba(196, 6, 78, 0.06);
    border-radius: 0px 0px 30px 30px;
    background-color: $white;


    &--animation {
        overflow: hidden;
        min-height: rem-calc(570);
    }


}

.block-content {
    margin-bottom: rem-calc(80);

    @include breakpoint(large) {
        margin-bottom: rem-calc(180);
    }

    @include breakpoint(medium down) {
        text-align: center;
    }

    .headline {
        line-height: 100%;
        letter-spacing: -0.01em;

        @include breakpoint(medium) {
            font-size: rem-calc(54);
        }
    }

    .lead {
        @include breakpoint(medium) {
            font-size: rem-calc(22);
            max-width: rem-calc(360);
        }
    }

    &.absolute-img {
        margin-top: rem-calc(40);
        position: relative;

        .block-content__img {

            @include breakpoint(xlarge) {
                min-width: 830px;
                position: absolute;
                top: rem-calc(240);
                transform: translateY(-50%);
                left: 50%;
            }
        }
    }
}

.grid-container {
    .block-content {
        &:nth-child(odd) {
            position: relative;

            @include breakpoint(large) {
                .block-content__img {
                    text-align: right;
                }
            }
        }

        &:last-child {
            @include breakpoint(small only) {
                margin-bottom: 0;
            }
        }
    }
}

.block-security {
    display: flex;

    flex-flow: row;
    margin: rem-calc(40 0 0 0);

    @include breakpoint(medium) {
        margin: rem-calc(50 0 0 35);
    }

    &__content {
        margin-left: rem-calc(18);

        .lead {
            margin-bottom: rem-calc(0);
        }

        div:not(.lead) {

            @include breakpoint(medium) {
                font-size: rem-calc(18);
            }
        }
    }
}



.team {
    @include breakpoint(medium) {
        max-width: rem-calc(1060);
        margin: 0 auto;
    }

    .team-member {
        text-align: center;
        margin-top: rem-calc(30);
        color: $black;


        &__photo {
            border-radius: 50%;
            max-width: rem-calc(140);
            height: rem-calc(140);
            background-color: $primary-color;
            margin: 0 auto;
            margin-bottom: rem-calc(20);

            overflow: hidden;
            font-size: rem-calc(22);
            font-weight: $global-weight-bold;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform $global-transition, box-shadow $global-transition;

            @include breakpoint(medium) {
                width: rem-calc(170);
                height: rem-calc(170);
                max-width: none;
            }

            img {
                max-width: 103%;
                object-fit: cover;

                @include breakpoint(medium) {
                    width: rem-calc(170);
                    height: rem-calc(170);

                }
            }
        }

        &__desc {
            .lead {
                margin-bottom: 0;
                font-weight: $global-weight-bold;
                transition: color $global-transition;
            }

            p:not(.lead) {
                font-size: rem-calc(18);
                line-height: rem-calc(22);
            }
        }
    }

    a.team-member {
        &:hover {
            cursor: pointer;

            .lead {
                color: $primary-color;
            }

            .team-member__photo {
                transform: translateY(-3px);
                box-shadow: 0 10px 35px rgba($primary-color, 0.18);
            }
        }
    }

    &--horizontal {
        max-width: none;

        .team-member {
            @include breakpoint(large) {
                display: flex;
                flex-flow: row;
                align-items: center;
                text-align: left;
            }

            &__photo {
                width: rem-calc(100);
                height: rem-calc(100);
                flex: 0 0 auto;
                background-color: #C4C4C4;

                img {
                    object-fit: contain;
                }
            }

            &__desc {

                @include breakpoint(large) {
                    margin-left: rem-calc(20);
                }

                @include breakpoint(small only) {
                    p:not(.lead) {
                        font-size: rem-calc(15);
                        line-height: rem-calc(20);
                    }
                }
            }
        }
    }
}

.img-get-most {

    @include breakpoint(medium) {
        position: relative;
        top: 80px;
        left: 25px;
    }
}

.counter {
    width: rem-calc(40);
    height: rem-calc(40);
    border-radius: 50%;
    font-size: 24px;
    line-height: 100%;
    color: $white;
    font-weight: bold;
    background-color: #F9D155;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem-calc(20);

    @include breakpoint(medium down) {
        margin: 0 auto;
        margin-bottom: rem-calc(15);
    }

    &.green {
        background-color: #85C33D;
    }

    &.orange {
        background-color: #E96E4C;
    }

    &.purple {
        background-color: #4050D1;
    }
}

.elia-video-container {
    max-width: unset;
}

.elia-custom-container {
    min-height: unset;
    height: calc(100vh - 130px);
    overflow: unset;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: fit-content;
    max-height: fit-content;
    padding: 0 0.97rem;
}

.elia-custom-container video {
    width: auto;
    height: 100%;
    border-radius: 0 0 30px 30px;
    max-width: 1920px !important;
    max-height: 1080px !important;
}

@media only screen and (min-width: 1920px) {
    .elia-custom-container {
        max-width: 1920px;
        max-height: 1080px;
    }
}