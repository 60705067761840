[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
    font-size: rem-calc(18);
    height: rem-calc(60);
    padding: rem-calc(15);
    border-radius: $global-radius;
}

.input-group {
    margin-bottom: rem-calc(15);

    @include breakpoint(small only) {
        display: block;
    }

    .input-group-field {
        border-radius: $global-radius;
    }

    .input-group-button {
        height: rem-calc(48);

        @include breakpoint(small only) {
            margin-top: rem-calc(10);
        }

        .button {
            margin-left: rem-calc(10);
            font-size: rem-calc(25);
            border-radius: $global-radius;
            font-weight: $global-weight-bold;
            min-width: rem-calc(235);
            height: rem-calc(60);


            @include breakpoint(small only) {
                width: 100%;
                margin-left: 0;
            }
        }
    }
}

.form-response{
    margin-top: 10px;
    font-size: 15px;
}

/* Checkbox styles */

input[type=checkbox]+label {
    display: block;
    cursor: pointer;
    margin: rem-calc(7 0);
    margin-left: 0 !important;
}

input[type=checkbox] {
    display: none;
}

input[type=checkbox]+label:before {
    content: "";
    display: inline-block;
    width: rem-calc(16);
    height: rem-calc(16);
    background-color: transparent;
    border: 1px solid $white;
    margin-right: rem-calc(10);
    margin-bottom: rem-calc(-3);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    //background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.5 1L3.5 6L1 3.5' stroke='%230067AB' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

input[type=checkbox]:checked+label:before {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='1' d='M8.5 1L3.5 6L1 3.5' stroke='%23ffffff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-color: $primary-color;
}