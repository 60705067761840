/* Webfont: Reforma1969 Gris */
@font-face {
    font-family: 'Reforma1969';
    src: url('../fonts/Reforma1918-Gris.woff2') format('woff2'),
        /* /* Super Modern Browsers */
        url('../fonts/Reforma1969-Gris.woff') format('woff');
    /* Safari, Android, iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

h1,
.h1 {
    letter-spacing: -1px;
}

.f-reforma {
    font-family: 'Reforma1969';
}

.lead {
    font-size: rem-calc(22);
    line-height: 134%;
    letter-spacing: -0.02em;
    margin-bottom: rem-calc(30);
    @include breakpoint(medium) {
        font-size: rem-calc(25);
        line-height: 134%;
    }
}

.muted {
    color: $dark-gray;
}

.list {
    list-style: none;
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 0;

    &--timeline {
        margin-top: rem-calc(100);
        counter-reset: list;
        position: relative;
        @include breakpoint(small only) {
            margin-left: 25px;
        }

        &>li {
            position: relative;
            margin: rem-calc(40 0 55 35);

            &:before {
                counter-increment: list;
                content: counter(list);
                position: absolute;
                z-index: 2;
                left: rem-calc(-60);
                top: rem-calc(-10);
                width: rem-calc(40);
                height: rem-calc(40);
                background-color: $black;
                border-radius: 100%;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                line-height: 100%;
                font-family: $body-font-family;
                font-weight: bold;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: rem-calc(-40);
                height: 135%;
                width: 1px;
                background-color: $primary-color;
            }

            &:nth-last-child(2) {
                &:after {
                    height: 85%;
                }
            }

            .headline {
                margin-bottom: rem-calc(5);
                font-size: rem-calc(24);
                line-height: 100%;
            }

            img {
                margin-bottom: rem-calc(5);
            }

            p {
                font-size: rem-calc(18);
                line-height: 120%;
            }

        }

        .list--timeline__icon {
            position: absolute;
            left: -13px;
            bottom: -7px;
            margin: 0;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    &--download {
        display: flex;
        margin-bottom: rem-calc(40);

        li {
            margin-right: rem-calc(15);

            a {
                padding: 0;
            }
        }
    }
}

.link {
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    outline: none;
    display: inline-block;
  
    &:before {
      content: '';
      transform-origin: 100% 50%;
      transform: scale3d(0, 1, 1);
      transition: transform 0.3s ease;
      position: absolute;
      width: 100%;
      height: 1px;
      background: rgba($primary-color, 0.4);
      top: 100%;
      left: 0;
      pointer-events: none;
     
    }
  
    &:hover {
      &:before {
        transform-origin: 0% 50%;
        transform: scale3d(1, 1, 1);
      }
    }
  }