.footer {
    padding: rem-calc(70 0);

    @include breakpoint(small only) {
        text-align: center;
        padding: rem-calc(40 0);

        .menu {
            flex-flow: column;
            margin-top: rem-calc(30);

        }
    }


    .grid-container {
 

        &:first-child {
            margin-bottom: rem-calc(60);

            @include breakpoint(medium only) {
                font-size: rem-calc(14);
            }

            .menu li a {
                // @extend .link;
                color: $black;
                transition: color $global-transition;

                &:hover {
                    color: $primary-color;
                    text-decoration: underline;
                }
            }
        }
    }

    .menu--social {
        flex-flow: row;
        @include breakpoint(small only) {
            justify-content: center;
        }
        li {
            a {
                background-color: transparent;
                padding: rem-calc(0);
                margin-right: rem-calc(5);
            }
        }
    }

    .copyright {
        color: rgba($black, 0.4);
        line-height: 120%;
    }
}