.swiper-wrapper {
    box-sizing: inherit;
   
}

.swiper-container {
    width: 100%;
    overflow: visible;
    @include breakpoint(medium) {
        overflow: hidden;
    }

}

.swiper--team {
    @include breakpoint(medium) {
        max-width: calc(100% - 240px);
    }
    
    .swiper-slide {
        width: rem-calc(280);
        @include breakpoint(860 down) {
            width: 38vw;
           
        }

        &:last-child {
            margin-right: 0 !important;
        }
    }
}


.swiper-pagination {
    align-items: center;
    justify-content: center;
    pointer-events: all !important;
    z-index: 5;
    cursor: pointer;
    margin-top: rem-calc(47);
    display: flex;
    @include breakpoint(medium) {
        margin-top: rem-calc(77);
    }
    

    .swiper-pagination-bullet {
        flex: 0 0 auto;
        width: rem-calc(6);
        height: rem-calc(6);
        border-radius: 50%;
        background-color: #C4C4C4;
        margin: rem-calc(0 5 0 0);
        transition: background-color $global-transition;
        @include breakpoint(medium) {
            width: rem-calc(6);
            height: rem-calc(6);
            margin: rem-calc(0 4);
        }

        &.swiper-pagination-bullet-active {
            background-color: $primary-color;
           
        }
    }

    &.wide {
        justify-content: space-between;
    }
}


.swiper-controls {
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-button {
    display: block;
    background-color: transparent;
    width: rem-calc(36);
    height: rem-calc(36);

    background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_937_1687)'%3E%3Cpath d='M8.79126 35.6968C8.9818 35.8888 9.24066 35.9976 9.51126 35.9991C9.78266 36.0017 10.0431 35.8922 10.2313 35.6968L27.2089 18.7192C27.4014 18.5291 27.5098 18.2698 27.5098 17.9992C27.5098 17.7286 27.4014 17.4693 27.2089 17.2792L10.2313 0.297977C10.0427 0.0962965 9.78033 -0.0200825 9.50424 -0.0247625C9.22826 -0.0293327 8.96214 0.0782455 8.7669 0.273479C8.57166 0.468713 8.46396 0.734849 8.46865 1.01094C8.47322 1.28692 8.58971 1.54929 8.79127 1.73796L25.0489 17.9992L8.79127 34.2567C8.59956 34.4473 8.49186 34.7064 8.49186 34.9767C8.49186 35.247 8.59955 35.5061 8.79127 35.6967L8.79126 35.6968Z' fill='%23191919'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_937_1687'%3E%3Crect width='36' height='36' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: 35%;
    background-position: center;
    background-repeat: no-repeat;
    transition: all $global-transition;
    outline: none;
    cursor: pointer;
    margin-left: rem-calc(12);
    position: absolute;
    right: rem-calc(0);
    top: rem-calc(90);
    z-index: 6;
    @include breakpoint(small only) {
        display: none;
    }

    @include breakpoint(medium) {
        background-size: auto;
        width: rem-calc(100);
        height: rem-calc(100);

    }

    &.swiper-button-disabled {
        opacity: 0.5;
        background-color: transparent;

        &:hover {
            opacity: 0.5;
        }
    }

    &:hover {
        opacity: 1;
        background-color: rgba($primary-color, 0.2);
    }

}




  