.button {
    font-size: rem-calc(18);
    font-weight: 500;

    &.large {
        font-size: rem-calc(26);
        line-height: 89.7%;
        font-weight: 700;
    }

    &.light {
        color: $primary-color;
        background: rgba(196, 6, 78, 0.15);

        &:hover {
            background: rgba(196, 6, 78, 0.25);
        }
    }

    &.white {
        border: 1px solid $light-gray;
        color: $black;
        background-color: $white;

        &:hover {
            background: #FCF6F8;
        }
    }

    &.ghost {
        border: 1px solid $primary-color;
        background-color: transparent;
        color: $primary-color;

        &:hover {
            background: rgba(196, 6, 78, 0.15);
        }

        &.white {
            border-color: $white;
            border-width: 2px;
            color: $white;
            font-weight: bold;
            font-size: rem-calc(26);
            padding: rem-calc(15);
            &:hover {
                background: #FCF6F8;
                color: $primary-color;
            }
        }
    }

    &.plain {
        background-color: transparent;
        border: 0;
        color: $black;

        &:hover {
            background: rgba(196, 6, 78, 0.15);
        }
    }

    &.social {
        background-color: $white;
        font-size: rem-calc(20);
        line-height: 89.7%;
        color: $primary-color;
        display: flex;
        align-items: center;
        font-weight: bold;
        padding: rem-calc(17 20);
        width: 100%;
        
        @include breakpoint(medium) {
            font-size: rem-calc(26);
        }
        svg{
            margin-right: rem-calc(10);
        }
        &:hover {
            background: #FCF6F8;
            
        }
    }
}

.download-buttons {
    margin-bottom: 0;

    li {
        display: none;

        &.visible {
            display: block;
        }
    }
}